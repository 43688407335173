<template>
  <div>
    <div class="d-flex">
      <b-button
        @click="$bvModal.show(`create-base-ra`)"
        size="sm"
        class="mr-4"
        variant="primary"
      >
        Crear {{ $getVisibleNames("teaching.basera", false, "RA Base") }}
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        @click="$bvModal.show(`copy-base-ra`)"
      >
        Asociar {{ $getVisibleNames("teaching.basera", true, "RA Bases") }}
      </b-button>
    </div>
    <template v-if="raBasesFiltered.length > 0">
      <div class="ra-base-list-container">
        <b-list-group>
          <div class="ra-base-list-header">Redacción</div>
          <draggable v-model="raBasesFiltered" handle=".handle">
            <b-list-group-item
              v-for="ra_base in raBasesFiltered"
              :key="ra_base.id"
              class="d-flex"
            >
              <div class="mr-2 handle grabbable">
                <b-icon icon="arrows-expand" scale="1.2"></b-icon>
              </div>
              {{ ra_base.order }}.
              <SentenceContainer :Sentence="ra_base"> </SentenceContainer>
              <button-edit
                @click="$bvModal.show(`edit-base-ra-modal-${ra_base.id}`)"
                class="btn-edit-base-ra"
                v-b-tooltip.v-secondary.auto.noninteractive
                :title="`Editar ${$getVisibleNames(
                  'teaching.basera',
                  false,
                  'RA Base'
                )}`"
              ></button-edit>
              <b-button
                class="p-1"
                variant="none"
                @click="askForDeleteRABase(ra_base)"
                v-b-tooltip.v-secondary.auto.noninteractive="
                  `Eliminar ${$getVisibleNames(
                    'teaching.basera',
                    false,
                    'RA Base'
                  )}`
                "
              >
                <b-icon icon="trash-fill" scale="0.9"></b-icon>
              </b-button>
              <b-modal
                :id="`edit-base-ra-modal-${ra_base.id}`"
                hide-footer
                :title="
                  'Editar ' +
                  $getVisibleNames('teaching.basera', false, 'RA Base')
                "
                size="lg"
              >
                <RaBaseForm
                  :RABase="ra_base"
                  :competences_ids="ra_base.competences"
                  @updated="slotUpdatedRaBase"
                ></RaBaseForm>
              </b-modal>
            </b-list-group-item>
          </draggable>
        </b-list-group>
      </div>
    </template>
    <template v-if="!raBasesFiltered.length">
      <div class="empty-ra-alert">
        <b-icon icon="exclamation-circle" class="mr-1"></b-icon> Esta
        {{ $getVisibleNames("mesh.competence", false, "Competencia") }} no
        cuenta con ningún
        {{ $getVisibleNames("teaching.basera", false, "RA Base") }}.
      </div>
    </template>

    <b-modal
      :id="`create-base-ra`"
      hide-footer
      :title="$getVisibleNames('teaching.basera', false, 'RA Base')"
      size="lg"
    >
      <RaBaseForm
        :competences_ids="[competence_id]"
        @created="slotCreatedRaBase"
      ></RaBaseForm>
    </b-modal>
    <b-modal
      :id="`copy-base-ra`"
      hide-footer
      :title="`Seleccione los ${$getVisibleNames(
        'teaching.basera',
        false,
        'RA Base'
      )} a asociar.`"
      size="lg"
    >
      <SelectedRaBase
        :competence_id="competence_id"
        @closeModal="$bvModal.hide(`copy-base-ra`)"
      ></SelectedRaBase>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "RaBase",
  components: {
    RaBaseForm: () => import("@/components/reusable/RaBaseForm"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    SelectedRaBase: () => import("@/components/reusable/SelectedRaBase"),
    draggable: () => import("vuedraggable"),
  },
  props: {
    competence_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      ra_bases: names.RA_BASES,
    }),
    raBasesFiltered: {
      get() {
        let list = this.ra_bases
          .filter((x) => x.competences.includes(this.competence_id))
          .sort(function (a, b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              let payload = {
                ra_base_id: element.id,
                item: {
                  order: element.order,
                },
              };
              this.$store.dispatch(names.PATCH_RA_BASE, payload);
            }
          }
          order += 1;
        });
      },
    },
    // raBasesFiltered() {
    //   if (!this.competence_id) return;
    //   return this.ra_bases.filter((x) => x.competence == this.competence_id);
    // },
  },
  methods: {
    slotCreatedRaBase() {
      this.$bvModal.hide(`create-base-ra`);
    },
    slotUpdatedRaBase(ra_base) {
      this.$bvModal.hide(`edit-base-ra-modal-${ra_base.id}`);
    },
    askForDeleteRABase(ra_base) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar la asociación de esta instancia de "${this.$getVisibleNames(
          "teaching.basera",
          false,
          "RA Base"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          let competences = ra_base.competences;
          const index = competences.findIndex((x) => x == this.competence_id);
          if (index != -1) {
            competences.splice(index, 1);
            ra_base.competences = competences;
            let payload = {
              ra_base_id: ra_base.id,
              item: {
                competences: competences,
              },
            };
            this.$store.dispatch(names.PATCH_RA_BASE, payload);
            toast(
              String(
                this.$getVisibleNames("teaching.basera", false, "RA Base") +
                  " eliminado."
              )
            );
          }
        }
      });
    },
  },
  created() {
    // this.$store.dispatch(names.FETCH_RA_BASES, this.competence_id);
  },
};
</script>

<style scoped>
.ra-base-list-container {
  margin-top: 1rem;
  margin-bottom: 1.2rem;
  width: 100%;
}
.create-base-ra {
  display: flex;
  margin-right: auto;
  margin-left: 0;
}
.btn-edit-base-ra {
  margin-left: auto;
  margin-right: 0;
}
.ra-base-list-header {
  background: var(--primary-color);
  padding: 0.15rem 0;
  padding-left: 2.3rem;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.empty-ra-alert {
  background: #eee;
  margin-top: 1rem;
  padding: 2em;
  text-align: center;
  border-radius: 3px;
}
.grabbing * {
  cursor: grabbing !important;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>