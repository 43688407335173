var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-4",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.$bvModal.show(`create-base-ra`)}}},[_vm._v(" Crear "+_vm._s(_vm.$getVisibleNames("teaching.basera", false, "RA Base"))+" ")]),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.$bvModal.show(`copy-base-ra`)}}},[_vm._v(" Asociar "+_vm._s(_vm.$getVisibleNames("teaching.basera", true, "RA Bases"))+" ")])],1),(_vm.raBasesFiltered.length > 0)?[_c('div',{staticClass:"ra-base-list-container"},[_c('b-list-group',[_c('div',{staticClass:"ra-base-list-header"},[_vm._v("Redacción")]),_c('draggable',{attrs:{"handle":".handle"},model:{value:(_vm.raBasesFiltered),callback:function ($$v) {_vm.raBasesFiltered=$$v},expression:"raBasesFiltered"}},_vm._l((_vm.raBasesFiltered),function(ra_base){return _c('b-list-group-item',{key:ra_base.id,staticClass:"d-flex"},[_c('div',{staticClass:"mr-2 handle grabbable"},[_c('b-icon',{attrs:{"icon":"arrows-expand","scale":"1.2"}})],1),_vm._v(" "+_vm._s(ra_base.order)+". "),_c('SentenceContainer',{attrs:{"Sentence":ra_base}}),_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}],staticClass:"btn-edit-base-ra",attrs:{"title":`Editar ${_vm.$getVisibleNames(
                'teaching.basera',
                false,
                'RA Base'
              )}`},on:{"click":function($event){return _vm.$bvModal.show(`edit-base-ra-modal-${ra_base.id}`)}}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
                `Eliminar ${_vm.$getVisibleNames(
                  'teaching.basera',
                  false,
                  'RA Base'
                )}`
              ),expression:"\n                `Eliminar ${$getVisibleNames(\n                  'teaching.basera',\n                  false,\n                  'RA Base'\n                )}`\n              ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}],staticClass:"p-1",attrs:{"variant":"none"},on:{"click":function($event){return _vm.askForDeleteRABase(ra_base)}}},[_c('b-icon',{attrs:{"icon":"trash-fill","scale":"0.9"}})],1),_c('b-modal',{attrs:{"id":`edit-base-ra-modal-${ra_base.id}`,"hide-footer":"","title":'Editar ' +
                _vm.$getVisibleNames('teaching.basera', false, 'RA Base'),"size":"lg"}},[_c('RaBaseForm',{attrs:{"RABase":ra_base,"competences_ids":ra_base.competences},on:{"updated":_vm.slotUpdatedRaBase}})],1)],1)}),1)],1)],1)]:_vm._e(),(!_vm.raBasesFiltered.length)?[_c('div',{staticClass:"empty-ra-alert"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-circle"}}),_vm._v(" Esta "+_vm._s(_vm.$getVisibleNames("mesh.competence", false, "Competencia"))+" no cuenta con ningún "+_vm._s(_vm.$getVisibleNames("teaching.basera", false, "RA Base"))+". ")],1)]:_vm._e(),_c('b-modal',{attrs:{"id":`create-base-ra`,"hide-footer":"","title":_vm.$getVisibleNames('teaching.basera', false, 'RA Base'),"size":"lg"}},[_c('RaBaseForm',{attrs:{"competences_ids":[_vm.competence_id]},on:{"created":_vm.slotCreatedRaBase}})],1),_c('b-modal',{attrs:{"id":`copy-base-ra`,"hide-footer":"","title":`Seleccione los ${_vm.$getVisibleNames(
      'teaching.basera',
      false,
      'RA Base'
    )} a asociar.`,"size":"lg"}},[_c('SelectedRaBase',{attrs:{"competence_id":_vm.competence_id},on:{"closeModal":function($event){return _vm.$bvModal.hide(`copy-base-ra`)}}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }